import React from 'react';

const Card = ({
  title = 'Lorem ipsum',
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis justo magna.',
  src,
}) => {
  return (
    <article className="shadow-lg">
      <img src={src} alt={title} className="mb-2 w-full" />
      <h3 className="text-2xl font-bold mb-2 px-4 font-pilsner">{title}</h3>
      <p className="mb-4 pb-4 px-4">{description}</p>
    </article>
  );
};

export default Card;
