import useMediaQuery from './useMediaQuery';

const useBreakpoints = () => ({
  isSmallerThanSmall: useMediaQuery('(max-width: 480px)'),
  isSmallerThanMedium: useMediaQuery('(max-width: 768px)'),
  isGreaterThanMedium: useMediaQuery('(min-width: 768px)'),
  isSmallerThanLarge: useMediaQuery('(max-width: 1068px)'),
  // isLarge: useMediaQuery('(max-width: 2560px)'),
});

export default useBreakpoints;
