import './App.css';
import Header from './components/Header';
import Carousel from './components/Carousel';
import nativePattern from './images/nativePattern.png';
import Card from './components/Card';
import accessControlSystem from './images/accessControlSystem.webp';
import solar from './images/solar1280.png'
import avigilon from './images/avigilon.webp';
import fireAlarm from './images/fireAlarm.webp';
import audioVideo from './images/audioVideo.webp';
import muscogeeCreekNation from './images/muscogeeCreekNation.webp';
import riverSpiritCasino from './images/riverSpiritCasino.webp';
import useBreakPoint from './hooks/useBreakPoint';
import twitterIcon from './images/twitterIcon.svg';
import facebookIcon from './images/facebookIcon.svg';

function App() {
  const {
    isSmallerThanSmall,
    isSmallerThanMedium,
    isGreaterThanMedium,
    isSmallerThanLarge,
  } = useBreakPoint();

  return (
    <div className="App">
      {/* Header */}
      <Header />

      {/* <img src={nativePattern} alt="native pattern" /> */}
      <div
        style={{
          backgroundImage: `url(${nativePattern})`,
          backgroundSize: 'contain',
          height: isSmallerThanSmall
            ? '24px'
            : isSmallerThanLarge
            ? '48px'
            : '64px',
        }}
        className="bg-repeat-x"
      />

      {/* Our Company & culture */}
      <Carousel autoplay>
        <Carousel.Slide
          title="Mission"
          content="Deliver the highest quality of professional security services based on trust and confidence"
          className="bg-techsicoNative-sm md:bg-techsicoNative-md lg:bg-techsicoNative-lg"
        />
        {/* Photo by Matt Hoffman on Unsplash */}
        {/* <span>Photo by <a href="https://unsplash.com/@__matthoffman__">Matt Hoffman</a> on <a href="https://unsplash.com/photos/ahwXZDO5fDs">Unsplash</a></span> */}
        <Carousel.Slide
          title="Vision"
          content="Exceed our clients specific and customized security needs"
          className="bg-leaf-sm md:bg-leaf-md lg:bg-leaf-lg"
        />
        {/* Photo by Ashim D'Silva on Unsplash */}
        {/* <span>Photo by <a href="https://unsplash.com/photos/WeYamle9fDM">Ashim D'Silva</a> on <a href="https://unsplash.com/photos/WeYamle9fDM">Unsplash</a></span> */}
        <Carousel.Slide
          title="Core Values"
          content="Quality partnerships, integrity, exceptional customer service, and company growth"
          className="bg-canyon-sm md:bg-canyon-md lg:bg-canyon-lg"
        />
      </Carousel>

      {/* Our Services */}
      <section className="mt-2">
        <header className="p-4 text-3xl font-bold">
          <h2 className="font-pilsner">Our Services</h2>
        </header>
        <div
          className={`mx-4 ${
            isGreaterThanMedium
              ? isSmallerThanLarge
                ? 'grid grid-cols-2 gap-4'
                : 'grid grid-cols-5 gap-4'
              : ''
          }`}
        >
          <Card
            title="Access Control Systems"
            description="Techsico Native is a certified low voltage company specializing in different Access Control systems."
            src={accessControlSystem}
            color="#40E0D0"
          />
          <Card
            title="Surveillance Systems"
            description="Techsico Native provides a variety of products for all manner of low voltage surveillance systems integration."
            src={avigilon}
            color="#E50000"
          />
          <Card
            title="Solar Power Systems"
            description="Techsico Native installs solar panels and EV charging stations."
            src={solar}
            color="#E50000"
          />
          {/* Photo by Scott Webb on Unsplash */}
          {/* <span>Photo by <a href="https://unsplash.com/@scottwebb?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Scott Webb</a> on <a href="https://unsplash.com/s/photos/fire-alarm?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */}
          <Card
            title="Fire Alarm System"
            description="Techsico Native partners provide excellent fire alarm service and experience to keep you and your employees safe."
            src={fireAlarm}
            color="#192030"
          />
          {/* Photo by Alberto Duo on Unsplash */}
          {/* <span>Photo by <a href="https://unsplash.com/@duo?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Alberto Duo</a> on <a href="https://unsplash.com/s/photos/audio-and-video?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span> */}
          <Card
            title="Audio and Video"
            description="We are trained to keep up with this fast-changing market. As audio and video play an increasingly larger role in the workplace, Techsico Native is certified and trained in top-of-the-line systems to configure the systems you need to your specifications."
            src={audioVideo}
            color="#F1EACD"
          />
        </div>
      </section>

      {/* Our Clients */}
      {/*  - muscogee creek nations mound building */}
      {/*  - river spirit casino resort */}
      <section className="mt-2">
        <header className="p-4 text-3xl font-bold">
          <h2 className="font-pilsner">Our Clients</h2>
        </header>
        <div
          className={`mx-4 ${
            isGreaterThanMedium ? 'grid grid-cols-2 gap-4' : ''
          }`}
        >
          <Card
            title="Muscogee Creek Nation"
            description="Techsico Native has replaced the Audio Video system for the council chambers. While under construction Techsico Native has been tasked to break down and store the system at our insured warehouse."
            src={muscogeeCreekNation}
            color="#40E0D0"
          />
          <Card
            title="River Spirit Casino Resort"
            description="Techsico Native helped south parking cameras back to working order after record breaking flood, this required replacing PTZ cameras and troubleshooting fiber modules. Also, Techsico Native got all cameras back up and operational by opening time within 24 hours."
            src={riverSpiritCasino}
            color="#E50000"
          />
        </div>
      </section>

      {/* Our Partners */}
      {/*  - Avigilon, ACTi, ONSSI, S2, Bosch, Pelco, UniView */}
      {/*  - Interlogics, Visionis, Dahua */}
      <section className="my-8 lg:mb-16">
        <header className="p-4 text-3xl font-bold">
          <h2 className="font-pilsner">Our Partners</h2>
        </header>
        <ul className="grid gap-4 grid-cols-autofit justify-items-center items-center text-lg mx-20 lg:mx-40 lg:text-xl xl:grid-cols-5 xl:mx-60">
          <li>
            <a
              href="https://www.acti.com"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              ACTi Corporation
            </a>
          </li>
          <li>
            <a
              href="https://www.avigilon.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              Avigilon
            </a>
          </li>
          <li>
            <a
              href="https://www.bosch.us/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              Bosch
            </a>
          </li>
          <li>
            <a
              href="https://www.dahuasecurity.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              Dahua
            </a>
          </li>
          <li>
            <a
              href="https://www.interlogix.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              Interlogix
            </a>
          </li>
          <li>
            <a
              href="https://www.pelco.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              Pelco
            </a>
          </li>
          <li>
            <a
              href="https://www.qognify.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              Qognify
            </a>
          </li>
          <li>
            <a
              href="https://www.s2sys.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              S2
            </a>
          </li>
          <li>
            <a
              href="https://www.uniview.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              unv
            </a>
          </li>
          <li>
            <a
              href="https://www.visionistech.com/"
              target="_blank"
              rel="noreferrer"
              className="partner"
            >
              Visionistech
            </a>
          </li>
        </ul>
      </section>

      <img src={nativePattern} alt="native pattern" />

      {/* Footer */}
      <footer
        className={`py-4 ${isSmallerThanLarge ? 'px-4' : 'px-8'}`}
        style={{ backgroundColor: '#00183d', color: '#ffffff' }}
      >
        <div
          className={`grid p-4 ${
            isSmallerThanSmall
              ? 'grid-cols-1'
              : isSmallerThanMedium
              ? 'grid-cols-2'
              : isSmallerThanLarge
              ? 'grid-cols-3'
              : 'grid-cols-4'
          }`}
        >
          <section
            className={`p-4 ${
              isSmallerThanSmall
                ? 'col-span-1'
                : isSmallerThanMedium
                ? 'col-span-2'
                : isSmallerThanLarge
                ? 'col-span-3'
                : 'col-span-1'
            }`}
          >
            <h2
              className="uppercase pb-2 text-lg font-pilsner"
              style={{ color: '#9e9e9e' }}
            >
              About Us
            </h2>
            <p>
              Founded in 2017, Techisco Native is a customer-driven technology
              company headquartered in Tulsa, OK. TECHSICO Native continues to
              grow the breadth of services we offer and the geographical
              footprint we serve.
            </p>
          </section>
          <section className="p-4">
            <h2
              className="uppercase pb-2 text-lg font-pilsner"
              style={{ color: '#9e9e9e' }}
            >
              Solutions
            </h2>
            <ul>
              <li>Audio / Video</li>
              <li>Voice & Data Cabling</li>
              <li>Fiber Optic Cabling</li>
              <li>Phone Systems</li>
              <li>CCTV & Surveillance</li>
              <li>Music & Paging Systems</li>
            </ul>
          </section>
          <section className="p-4">
            <h2
              className="uppercase pb-2 text-lg font-pilsner"
              style={{ color: '#9e9e9e' }}
            >
              Get in Touch
            </h2>
            <address className="inline-block border-b-2 border-solid border-white pb-2 mb-2">
              <a
                className="block"
                href="https://goo.gl/maps/6yx33DxcRjz6hhzX8"
                target="_blank"
                rel="noopener noreferrer"
              >
                5516 E 9th St E Ave
                <br />
                Tulsa, OK 74112
              </a>
            </address>
            <div>
              <p>
                <a href="tel:+1918-720-0004">+1 (918) 720-0004</a>
              </p>
              <p className="inline-block border-b-2 border-solid border-white pb-2 mb-2">
                Mon-Fri, 8am - 5pm
              </p>
            </div>
            <a href="mailto:info@techsiconative.com">info@techsiconative.com</a>
            <p>We reply within 24 hours</p>
          </section>
          <section className="p-4">
            <h2
              className="uppercase pb-2 text-lg font-pilsner"
              style={{ color: '#9e9e9e' }}
            >
              Follow Us
            </h2>
            <div className="inline py-2 pr-2">
              <a
                href="https://www.facebook.com/TechsicoNative"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="inline-block"
                  width="40"
                  height="40"
                  src={facebookIcon}
                  alt="Techsico's Facebook"
                />
              </a>
            </div>
            <div className="inline p-2">
              <a
                href="https://twitter.com/TechsicoNative"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="inline-block"
                  width="40"
                  height="40"
                  src={twitterIcon}
                  alt="Techsico's Twitter"
                />
              </a>
            </div>
          </section>
        </div>
        <p className="text-center text-sm mt-4">
          &copy;{` ${new Date().getFullYear()} `}
          <span className="uppercase">Techsico</span> Native <br/> Oklahoma License # 440776
        </p>
      </footer>
    </div>
  );
}

export default App;
