import React from 'react';
import Slide from './Slide';

const Directions = Object.freeze({
  left: 'left',
  right: 'right',
});

const Carousel = ({
  children,
  circular = true,
  autoplay: initialAutoplay = false,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  // touch-related states
  const [touchStartPoint, setTouchStartPoint] = React.useState(null);
  const [swipeTo, setSwipeTo] = React.useState(null);
  const [autoplay, setAutoplay] = React.useState(initialAutoplay);

  const lastChildIdx = children.length - 1;
  React.useEffect(() => {
    const next = () => {
      setCurrentSlideIndex(idx =>
        idx !== lastChildIdx ? ++idx : circular ? 0 : idx
      );
    };
    if (autoplay) {
      var intervalId = window.setInterval(next, 4000);
    }

    return () => {
      window.clearInterval(intervalId);
    };
  }, [autoplay, lastChildIdx, circular]);

  // helper
  const getCurrentTouchPointFrom = event => event.touches[0].clientX;
  // touchstart event handler
  const handleTouchStart = event => {
    // if use one finger
    if (Object.keys(event.touches).length === 1) {
      setTouchStartPoint(getCurrentTouchPointFrom(event));
    }
  };
  // touchmove event handler
  const handleTouchMove = event => {
    const offset = touchStartPoint - getCurrentTouchPointFrom(event);
    offset > 0
      ? setSwipeTo(Directions.left)
      : offset < 0
      ? setSwipeTo(Directions.right)
      : setSwipeTo(null);
  };
  // touchend event handler
  const handleTouchEnd = () => {
    if (swipeTo === Directions.left) {
      setCurrentSlideIndex(
        currentSlideIndex !== children.length - 1
          ? currentSlideIndex + 1
          : circular
          ? 0
          : currentSlideIndex
      );
    } else if (swipeTo === Directions.right) {
      setCurrentSlideIndex(
        currentSlideIndex !== 0
          ? currentSlideIndex - 1
          : circular
          ? children.length - 1
          : currentSlideIndex
      );
    }
  };

  const handleMouseEnter = () => {
    setAutoplay(false);
  };

  const handleMouseLeave = () => {
    setAutoplay(true);
  };

  return (
    <div
      className="text-center relative"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* display the default slide */}
      {children[currentSlideIndex]}
      {/* slide indicators */}
      <ul className="flex flex-row justify-center absolute bottom-0 right-0">
        {children.map((child, index) => (
          <li key={child.props.title}>
            <button
              className={`m-2 w-8 h-4  bg-opacity-50 ${
                currentSlideIndex === index ? 'bg-gray-400' : 'bg-gray-600'
              }`}
              onClick={() => setCurrentSlideIndex(index)}
            ></button>
          </li>
        ))}
      </ul>
    </div>
  );
};

Carousel.Slide = Slide;

export default Carousel;
