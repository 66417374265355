import React from 'react';
import './Slide.css';
import useBreakPoint from '../hooks/useBreakPoint';

const Slide = ({ title, content, className }) => {
  const {
    isSmallerThanSmall,
    isSmallerThanMedium,
    isSmallerThanLarge,
  } = useBreakPoint();

  return (
    // background image
    <div
      className={`${className} slide bg-no-repeat bg-cover flex justify-center items-center transition-background-image duration-500 ease-in-out`}
    >
      {/* centered wordbox */}
      <div
        className={`slide-box bg-black text-white bg-opacity-50 ${
          isSmallerThanMedium ? 'p-4' : 'p-8'
        }`}
      >
        <h2
          className={`font-pilsner font-bold ${
            isSmallerThanSmall
              ? 'text-3xl'
              : isSmallerThanMedium
              ? 'text-4xl'
              : isSmallerThanLarge
              ? 'text-5xl'
              : 'mb-2 text-6xl'
          }`}
        >
          {title}
        </h2>
        <p
          className={`slide-box-content ${
            isSmallerThanSmall
              ? 'text-lg'
              : isSmallerThanMedium
              ? 'text-xl'
              : isSmallerThanLarge
              ? 'text-2xl'
              : 'text-3xl'
          }`}
        >
          {content}
        </p>
      </div>
    </div>
  );
};

export default Slide;
